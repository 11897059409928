import { CLEAR_TNL_LIST_DATA, GET_TNL_LIST_SUCCESS, GET_TNL_SUCCESS } from 'actions/types';

export default function reducer(state = {isLoggedIn: false, role: false}, action) {
  switch (action.type) {

    case GET_TNL_LIST_SUCCESS:
      return {
        ...state,
        error: '',
        tnlList: action.data
      }
    case GET_TNL_SUCCESS:
      return {
        ...state,
        tnlIngredient: action.data
      }
    case CLEAR_TNL_LIST_DATA:
      return {
        ...state,
        error: '',
        tnlList: undefined,
      }
    default:
      return state;
  }
}


